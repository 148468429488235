import request from '../http/axios'
import Vue from 'vue'

function generalRecords (params, data) {
  request({
    url: `/color/oh/prisoner/${params.pageName}/${params.action}`,
    method: 'POST',
    data: {
      // extInfo
      recent: params.extInfo ? JSON.stringify(params.extInfo) : undefined,
      // userRandomKey
      damn: localStorage.getItem('userRandomKey'),
      // adid
      'mm-hmm': localStorage.getItem('adid') || null
    }
  })
}
Vue.prototype.$generalRecords = generalRecords
export default {
  generalRecords
}
