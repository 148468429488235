export default {
  orderDetail: '/color/custody/tree', // 订单详情
  bankInfo: '/color/alley/familiar', // 银行卡信息
  showAutoApply: '/color/custody/take', // 是否展示自动复借
  setAutoApply: '/color/custody/easily', // 是否自动复借
  repaymentDetails: '/color/custody/architecture', // 还款详情
  extensionOrderDetail: '/color/depending/combination', // 展期订单详情
  renewOrderUtr: '/color/custody/outdoor', // 更新订单utr
  customerService: '/color/oh/happen', // 获取客服信息
  complaint: '/color/long-term/meet' // 用户反馈
}
