<template>
  <div class="header">
    <div class="left">
      <img @click="toBack" src="@/assets/back.png" alt="">
    </div>
    <div class="right" @click="show = true" v-if="showFeed">
      <img src="@/assets/feedback.png" alt="">
    </div>
    <van-popup
      v-model="show"
      @close="closePop"
      closeable>
      <template>
        <div class="feedBack">
          <h5>Feedback</h5>
          <p class="subTip">Please provide a description of the issue you met:</p>
          <van-field
            v-model="feedBackText"
            rows="2"
            autosize
            type="textarea"
            maxlength="200"
            placeholder="Please enter"
            show-word-limit
          />
          <van-button color="#7578FF" round :loading="btnLoading" loading-type="spinner" @click="submit" :disabled="!feedBackText">Submit</van-button>
          <p class="phoneTip">You can also make a direct phone call to the customer service center:</p>
          <div class="copyPhone">
            <div class="left">{{customerPhone}}</div>
            <div class="btn" @click="copyPhone">Copy</div>
          </div>
        </div>
      </template>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getCustomerService, allComplaint } from '@/views/repaymentHome/lib.js'
export default {
  props: {
    goBack: {
      type: Function
    },
    showFeed: {
      default: true
    }
  },
  data () {
    return {
      show: false,
      feedBackText: '',
      btnLoading: false,
      customerPhone: ''
    }
  },
  created () {
    this.getCustomerPhone()
  },
  methods: {
    toBack () {
      if (this.goBack) {
        this.$emit('goBack')
        return
      }
      this.$router.go('-1')
    },
    copyPhone () {
      this.$copyText(this.customerPhone).then(() => {
        Toast('Copy successfully')
      })
    },
    getCustomerPhone () {
      getCustomerService().then(res => {
        if (res.drawer === '200') {
          this.customerPhone = res.final.huge
        }
      })
    },
    submit () {
      this.btnLoading = true
      allComplaint({
        diagnosis: this.feedBackText,
        propose: 1
      }).then(res => {
        if (res.drawer === '200') {
          this.$toast('Enviado con éxito')
          this.feedBackText = ''
          this.show = false
        }
      }).finally(() => {
        this.btnLoading = false
      })
    },
    closePop () {
      this.show = false
      this.feedBackText = ''
    }
  }
}
</script>

<style scoped lang="less">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    img {
      width: 22px;
      height: 22px;
    }
  }
  .right {
    img {
      width: 44px;
      height: 44px;
    }
  }
}
.van-popup {
  width: 340px;
  padding: 30px 15px 15px 15px;
  background: linear-gradient( #DEDEFF 0%, rgba(255,255,255,1) 100%);
  border-radius: 16px;
}
.feedBack {
  h5 {
    color: #010101;
    font-size: 24px;
  }
  .subTip {
    color: #626C89;
    font-size: 14px;
    text-align: left;
  }
}
.van-field {
  // background-color: rgba(245, 247, 252, 0.6);
  background-color: #F5F5FC;
  border-radius: 12px;
  margin: 10px 0;
}
.van-button {
  width: 100%;
  color: #fff !important;
  font-size: 18px;
}
.phoneTip {
  color: #626C89;
  font-size: 13px;
  font-weight: 500;
  margin: 10px 0;
}
.copyPhone {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: rgba(245, 247, 252, 0.6);
  border-radius: 12px;
  padding: 9px 8px 8px 14px;
  .left {
    color: #061237;
    font-size: 14px;
  }
  .btn {
    width: 82px;
    height: 30px;
    background-color: #69BDC7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    color: #fff;
    font-size: 15px;
  }
}
</style>
